import axios from "axios"

export async function applyAsaTeacher (data, cb){

    let formData = new FormData()

    for(let key in data){
        formData.append(key, data[key])
    }

    let sendData = await axios.post('/api/v1/s/become-a-teacher/', {...formData}, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })

    cb({type: sendData.data.status, message:sendData.data.response.statusDescription})
}

export async function verifyYourPayement(data, cb) {
    let verifyPayement = await axios.post('/api/v1/s/process-payment-v2/', {...data}, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })

    cb({type: verifyPayement.data.status, message: verifyPayement.data.response.statusDescription})
}

export async function getDemoLectures(id, cb) {
    // let demoLectureData = await axios.get(`/api/v1/s/get-demo-lecture/?standard_id=${id}`)
    let demoLectureData = await axios.get(`/api/v1/s/get-demo-lecture`)

    cb({type: demoLectureData.data.status,data: demoLectureData.data.response.data.Lecture, message: demoLectureData.data.response.statusDescription})
}

export async function whyEduaidLive (cb) {
    let content = await axios.get('/api/v1/s/why-eduaid-live/')

    if(content.data.status == 'error'){
        cb({type: content.data.status, message: content.data.response.statusDescription})
    }else{
        cb({type: content.data.status, message: content.data.response.statusDescription, data: content.data.response.data.WhyEduaidLive})
    }
}


export async function getDLectureData (cb) {
    let content = await axios.get('/api/v1/s/get-demo-lecture/')

    if(content.data.status == 'error'){
        cb({type: content.data.status, message: content.data.response.statusDescription})
    }else{
        cb({type: content.data.status, message: content.data.response.statusDescription, data: content.data.response.data.Lecture})
    }
}

export async function getPlanWiseAddOns (id, cb) {
    let ad_ons = await axios.get(`/api/v1/s/get-planwise-addons/?plan_id=${id}`)

    if(ad_ons.data.status == "error"){
        cb({type: ad_ons.data.status, message: ad_ons.data.response.statusDescription})
    }else{
        cb({type: ad_ons.data.status, data: ad_ons.data.response.data.Addon_List})
    }
}