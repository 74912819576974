import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

// let default_sign = {"time_stamp":1659962738805,"signature":"0xbfb020073b5840cd1bddafb73becfb8d66e582715b98e8dc6564875082ef43680ce4b25767525c830c204baf508a171076181691744f63f48e6424c109d70ef61c"};
// axios.defaults.headers={...axios.defaults.headers,...default_sign};

axios.interceptors.response.use(undefined,(err) => {
  if (err.response && [400,401,403,404,500].includes(err.response.status)){
    return err.response
  }
})

export function userLoginWithPhone(data, cb) {
  return async dispatch => {
    dispatch({
      type: "LOGIN_REQUEST"
    })
    try{
      let loggedInUser = await axios.post('/api/v1/s/login-wd-mobile-no-v2/', {...data})

      if(loggedInUser.data.status == 'success'){
        dispatch({
          type: "LOGIN_SUCCESS",
          data: loggedInUser.data.response.data
        })

        cb({type: loggedInUser.data.status, data: loggedInUser.data.response.data, message: loggedInUser.data.response.statusDescription})
      }else{
        dispatch({
          type: "LOGIN_ERROR",
          message: loggedInUser.data.response.statusDescription
        })
        cb({type: loggedInUser.data.status, message: loggedInUser.data.response.statusDescription })
      }
    }catch(e){
      dispatch({
        type: "LOGIN_TYPE_RESET"
      })
      cb({type: "error", message: e.message})
    }
  }
}

export function setUserInfo(data) {
  return async dispatch => {
    data = JSON.parse(data)

    await dispatch({
      type: "USER_SET_SUCCESS",
      data
    })
  }
}

export function verifyPOtp(data, cb){
  return async dispatch => {
    dispatch({
      type: "LOGIN_REQUEST"
    })

    try{
      let verifyOtp = await axios.post('/api/v1/s/verify-login-otp/', {...data})


    if(verifyOtp.data.status =='success'){

      axios.defaults.headers = {...axios.defaults.headers, Authorization: `Bearer ${verifyOtp.data.response.data.token}`}
      dispatch({
        type: "LOGIN_SUCCESS",
        data: verifyOtp.data.response.data
      })


      cb({type: verifyOtp.data.status, message: verifyOtp.data.response.statusDescription})
    }else{
      dispatch({
        type: "LOGIN_ERROR",
        message: verifyOtp.data.response.statusDescription
      })
      cb({type: verifyOtp.data.status, message: verifyOtp.data.response.statusDescription})
    }
    }catch(e){
      dispatch({
        type: "LOGIN_TYPE_RESET"
      })
      cb({type: "error", message: e.message})
    }
  }
} 

export function setUserPassword(data, cb) {
  return async dispatch => {
    dispatch({
      type: "LOGIN_REQUEST"
    })
    let setPwd = await axios.post('/api/v1/s/reset-password/', {...data})

    if(setPwd.data.status == 'success'){
      dispatch({
        type: "LOGIN_SUCCESS",
        data: setPwd.data.response.data
      })

      cb({type: setPwd.data.status, message: setPwd.data.response.statusDescription})
    }else{
      dispatch({
        type: "LOGIN_ERROR",
        message: setPwd.data.response.statusDescription
      })
      cb({type: setPwd.data.status, message: setPwd.data.response.statusDescription})
    }

  }
}



export const setBoardId = (id) => {
  return async dispatch => {
    dispatch({
      type: "SET_BOARD_ID_SUCCESS",
      id
    })
  }
}

export const setStandardId = (id) => {
  return async dispatch => {
    dispatch({
      type: "SET_STANDARD_ID_SUCCESS",
      id
    })
    dispatch({
      type: "CHANGE_STANDARD_ID",
      id
    })
  }
}

export function loginWithPassword(data, cb) {
  return async dispatch => {
    dispatch({
      type: "LOGIN_REQUEST"
    })

    let loginUser = await axios.post('/api/v1/s/login-password/', {...data})

    
    if(loginUser.data.status == 'success'){
      axios.defaults.headers = {...axios.defaults.headers, Authorization: `Bearer ${loginUser.data.response.data.token}`}
      
      let existing_data = JSON.parse(localStorage.getItem("user"))
      // It will expire in 3 hours
      existing_data = {...existing_data,token: `Bearer ${loginUser.data.response.data.token}`, ...JSON.parse(loginUser.data.response.data.student_details),expire_time: (new Date().getTime() + 10800000) }

      localStorage.setItem("user", JSON.stringify(existing_data));

      if(loginUser.data.response.data.student_details){
        dispatch({
          type: "SET_BOARD_ID_SUCCESS",
          id: JSON.parse(loginUser.data.response.data.student_details).board_id
        })
        
        dispatch({
          type: "SET_STANDARD_ID_SUCCESS",
          id: JSON.parse(loginUser.data.response.data.student_details).standard_id
        })
        dispatch({
          type: "CHANGE_STANDARD_ID",
          id: JSON.parse(loginUser.data.response.data.student_details).standard_id
        })
      }

      dispatch({
        type: "LOGIN_SUCCESS_FULL",
        data: JSON.parse(loginUser.data.response.data.student_details)
      })

      cb({type: loginUser.data.status, message: loginUser.data.response.statusDescription, alreadyUser:loginUser.data.response.data.student_details ? true : false })
    }else{
      dispatch({
        type: "LOGIN_ERROR",
        message: loginUser.data.response.statusDescription
      })
      cb({type: loginUser.data.status, message: loginUser.data.response.statusDescription, alreadyUser:loginUser.data.response.data.student_details ? true : false})
    }
  }
}

export function updateUserInfo(data, cb) {
  return async dispatch => {
    dispatch({
      type: "LOGIN_REQUEST"
    })
    
    let updateUProfile = await axios.put('/api/v1/s/student-profile/', {...data})

    if(updateUProfile.data.status == 'success'){
      dispatch({
        type: "LOGIN_SUCCESS",
        data: data
      })

      cb({type: updateUProfile.data.status, message: updateUProfile.data.response.statusDescription})
    }else{
      dispatch({
        type: "LOGIN_ERROR",
        message: updateUProfile.data.response.statusDescription
      })
      cb({type: updateUProfile.data.status, message: updateUProfile.data.response.statusDescription})
    }

  }
}

// Subscription functions

export function getSubsData(s_id, cb) {
  return async dispatch => {
    dispatch({
      type: "SUBSCRIPTION_REQUEST"
    })

    let subscriptionData = await axios.get(`/api/v1/s/get-vip-plans/?standard_id=${s_id}`)

    if(subscriptionData.data.status == 'success'){
      dispatch({
        type: "SUBSCRIPTION_SUCCESS",
        data: subscriptionData.data.response.data.plan
      })

      cb({type: subscriptionData.data.status, data: subscriptionData.data.response.data.plan})
    }else{
      dispatch({
        type: "SUBSCRIPTION_ERROR",
        message: subscriptionData.data.response.statusDescription
      })

      cb({type: subscriptionData.data.status, message: subscriptionData.data.response.statusDescription})
    }
  }
}

export const getBoardsWithStandards = () => {
  return async dispatch => {
    dispatch({
      type: "GET_BOARDS_REQUEST"
    })

    
    let getBoardData = await axios.get('/api/v1/s/get-board-with-standards')


    if(getBoardData.data.status == 'error'){
      dispatch({
        type: "GET_BOARDS_ERROR",
        message: getBoardData.data.response.statusDescription
      })
    }else{
      dispatch({
        type: "GET_BOARDS_SUCCESS",
        data: getBoardData.data.response.data.board
      })
    }
  }
}

export const forgotUserPassword = (no, cb) => {
  return async dispatch => {
    let data = {
      xcxzbczcxnzmbxbzcxkzxcj: no,
      vcvcvcxcvbzncvvcznxcxxcz: 'eduaid'
    }

    let forgotPRequest = await axios.post('/api/v1/s/forgot-password/', {...data})

    cb({type: forgotPRequest.data.status, message: forgotPRequest.data.response.statusDescription})
  }
}

export const setUserDataFromLocal = (data) => {
  return async dispatch => {
    axios.defaults.headers = await {...axios.defaults.headers, Authorization: data.token }

    dispatch({
      type: "LOGIN_SUCCESS_FULL",
      data: data
    })

    dispatch({
      type: "SET_BOARD_ID_SUCCESS",
      id: data.board_id
    })

    dispatch({
      type: "SET_STANDARD_ID_SUCCESS",
      id: data.standard_id
    })
  }
}

export const setCartDataFromLocal = (token, data) => {
  return async dispatch => {
    axios.defaults.headers = await {...axios.defaults.headers, Authorization: token }

    dispatch({
      type: "SET_CART_SUCCESS",
      data: data
    })
  }
}

export const addDataToCart = (type,data) => {
  return async dispatch => {
    if(type == "add"){
      dispatch({
        type: "ADD_TO_CART_SUCCESS",
        data: data
      })
    }else{
      dispatch({
        type: "REMOVE_FROM_CART_SUCCESS",
        data: data
      })
    }
  }
}

export const addCourse = (data) => {
  return async dispatch => {
    dispatch({
      type: "ADD_COURSE_SUCCESS",
      data: data
    })
  }
}