import React, { useEffect, useState } from "react";

import axios from "axios";
import { connect } from "react-redux";

import { getSubsData,addCourse } from "../../services/actions";
import { ToastContainer, toast } from 'react-toastify';

import { useNavigate } from "react-router-dom";
import { whyEduaidLive } from "../../utils";


function Subscription(props) {

  const [readData, setReadData] = useState({show: false, id: ""})
  
  const [subsData, setSubsData] = useState([])
  const [whyeduLiveData, setWhyEduLiveData] = useState({type: "", data: []})
  const [selectedSubs, setSelectedSubs] = useState(subsData[0]?.name)
  const {user,subscription, boards} = props

  const [featuresData, setFeaturesData] = useState({type: "", data: []})

  const [testimonialData, setTestimonialData] = useState({type: "", data: []})

  const [activeImageIndex, setActiveImageIndex] = useState(0)

  const [availableAddOns, setAvailableAddOns] = useState([])

  const [faqs, setFaqs] = useState({type: "", data: [], message: ""})

  const [studentImage, setStudentImage] = useState("")

  const [currentSelectedPlan, setCurrentSelectedPlan] = useState("")


  const navigate = useNavigate()

  useEffect(() => {
    props.getSubsData(user.data.standard_id , (res) => {
      if(res.type == 'success'){
        let sortedData = res.data.sort(function(a, b) {
          var keyA = a.price,
            keyB = b.price;
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        setSelectedSubs(sortedData[0].name)


        setSubsData(sortedData)
        setCurrentSelectedPlan(sortedData[0].id)
      }else{
        // toast.error(res.message)
      }
    })
  }, [user.data.standard_id]);



  const getAddOnsData = async () => {
    let getAddOns = await axios.get(`/api/v1/s/get-addons-all/?standard_id=${boards.selectedStandardId}`)

    console.log(getAddOns)

    if(getAddOns.data.status == 'error'){
      // toast.error(getAddOns.data.response.statusDescription)
    }else{
      setAvailableAddOns(getAddOns.data.response.data.addon)
    }
  }

  useEffect(() => {
    if(subsData.length > 0){
      setSelectedSubs(subsData[0].name)
    }

  }, [subsData])

  const getClientTestimonials = async () => {
    setTestimonialData({...testimonialData, type: "request"})
    let testimonials = await axios.get('/api/v1/s/get-testomonials/')

    if(testimonials.data.status == "error"){
        // toast.error(testimonials.data.response.statusDescription)
    }else{
        setTestimonialData({type: "success",data:testimonials.data.response.data.testimonial})
    }
  }

  const getFAQs = async () => {
    let faqData = await axios.get('/api/v1/s/get-faq/')

    if(faqData.data.status == 'error'){
      // toast.error(faqData.data.response.statusDescription)
    }else{
      setFaqs({type: "success", data: faqData.data.response.data.FAQ})
    }
  }

  const getWhyEduaidLive = () => {
    setWhyEduLiveData({...whyEduaidLive, type: 'request'})
    whyEduaidLive((res) => {
      if(res.type == "error"){
        setWhyEduLiveData({...whyEduaidLive, type: 'error'})
        // toast.error(res.message)
      }else{
        setWhyEduLiveData({type: 'success', data: res.data})
      }
    })
  }

  const loadStudentResultImage = async () => {
    let studentImageData = await axios.get(`/api/v1/s/student-results/?standard_id=`)

    setStudentImage(studentImageData.data.response.data.student_result[0].image)
  }

  useEffect(() => {
    getAddOnsData()
    getFAQs()
    getWhyEduaidLive()
    getClientTestimonials()
    loadStudentResultImage()
  }, [])

  const loadPlanFeatures = async () => {
    setFeaturesData({...featuresData, type: "request"})
    let pFeatures = await axios.get(`/api/v1/s/get-add-ons-by-vip-plans/?standard_id=${boards.selectedStandardId}`)

    if(pFeatures.data.status == "success"){
      setFeaturesData({...featuresData, type: "success", data: pFeatures.data.response.data.Addon_List})
    }else{
      setFeaturesData({...featuresData, type: "error", message: pFeatures.data.response.statusDescription})
    }
  }

  useEffect(() => {
    loadPlanFeatures()
  }, [boards.selectedStandardId])


  return (
    <>
      <ToastContainer />
      <section className="yellow py-3">
        <div className="container row mx-auto">
          <div className="col-md-3 col-12 d-block d-md-none">
            <img
              src="/assets/images/image_processing20191105-27405-1ct1ofv-removebg-preview 1.png"
              alt="1ct1ofv-removebg-preview"
              className="img-fluid"
            />
          </div>
          <div className="col-md-9 col-12 mb-5 md-md-2">
            <p>Home | {boards?.data?.filter((item) => item.id ==boards.selectedBoardId)[0]?.name} | {boards?.data?.filter((item) => item.id ==boards.selectedBoardId)[0]?.standards?.filter((s) => s.id == boards.selectedStandardId)[0]?.name_slug}</p>
            <div className="">
              {boards?.data?.filter((item) => item.id ==boards.selectedBoardId)[0]?.standards?.filter((s) => s.id == boards.selectedStandardId)[0]?.icon &&
              <img
                src={boards?.data?.filter((item) => item.id ==boards.selectedBoardId)[0]?.standards?.filter((s) => s.id == boards.selectedStandardId)[0]?.icon}
                alt="Group 11297.png"
                className="img-fluid"
              />
            }
              <h5>{boards?.data?.filter((item) => item.id ==boards.selectedBoardId)[0]?.name}</h5>
            </div>
            <p>
              {boards?.data?.filter((item) => item.id ==boards.selectedBoardId)[0]?.description}
            </p>
          </div>
          <div className="col-md-3 col-12 d-none d-md-block">
            <img
              src="/assets/images/image_processing20191105-27405-1ct1ofv-removebg-preview 1.png"
              alt="1ct1ofv-removebg-preview"
              className="img-fluid"
            />
          </div>
        </div>
      </section>
      <section className="container d-flex justify-content-center">
        <div className="card card-1 rounded-5 card-position">
          <div className="card-body">
            {/* <h5>{subsData[0]?.name}</h5> */}
            <div class="dropdown">
              <h5 style={{color: "#0055D2", cursor: "pointer"}} class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                {subsData?.filter((item) => item.id == currentSelectedPlan)[0]?.name}
              </h5>
              <ul class="dropdown-menu" style={{border: "1px solid"}}>
                {
                  subsData.map((data, index) => {
                    return(
                      <li onClick={() => {
                        setCurrentSelectedPlan(data.id)
                      }} style={{cursor: "pointer"}} key={index}><span class="dropdown-item">{data.name}</span></li>
                    )
                  })
                }
                {/* <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li> */}
              </ul>
            </div>
            <h5>What does this course include?</h5>
            <p className="m-0 mt-3">KEY POINT</p>
            <div className="card border-1 mb-3" style={{overflowX: "auto"}}>
              <div className="row d-flex card-body" style={{flexWrap: "initial"}}>
                {subsData?.filter((item) => item.id == currentSelectedPlan)[0]?.add_ons.map((data,index) => {
                  return(
                    <div className="card col-sm-12 col-md-3 py-4 py-md-0 rounded-0 card-custom">
                      <div className="card-body d-flex align-items-center p-0 m-0">
                        <img
                          src={data.icon}
                          width="50"
                          alt={data.name}
                        />
                        <p className="m-0 ps-1">{data.name}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <span className="fs-6">Our Courses starts from</span>
            <div className="d-flex justify-content-between">
              <h4>₹ {subsData?.filter((item) => item.id == currentSelectedPlan)[0]?.price}/-</h4>
              <button className="px-4 border-0 btn btn-color text-white" onClick={() => {
                props.addCourse( subsData?.filter((item) => item.id == currentSelectedPlan)[0])
                navigate('/cart', {state:  {data: {pageData: subsData?.filter((item) => item.id == currentSelectedPlan)[0], addOns:availableAddOns}}})
              }}>
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="container mb-4 mb-md-3">
        <div className="row">
          <div className="col-12 col-md-6 mb-3 mb-md-1">
            <img
              src={whyeduLiveData?.data?.filter((item, index) => index == activeImageIndex)[0]?.image}
              alt={whyeduLiveData?.data?.filter((item, index) => index == activeImageIndex)[0]?.title}
              className="img-fluid"
            />
          </div>
          <div className="col-12 col-md-6">
            <h2>
              Why <span>EduAid -</span> LIVE
            </h2>

            {whyeduLiveData.type == 'request' ?
              <h3 className="text-center">Loading...</h3>
              :
              whyeduLiveData.type == 'success' &&
              <div className="accordion" id="accordionExample">
                {whyeduLiveData.data.map((item, index) => {
                  return(
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded="true"
                          aria-controls={`collapse${index}`}
                          onClick={() => setActiveImageIndex(index)}
                        >
                          &nbsp; {item.title}
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${index == 0 ? "show" : ""}`}
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                        {item.description}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            }
          </div>
        </div>
      </section>
      <section className="container my-4 d-none d-md-block">
        <div className="card nav_card border-0">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <a
                type="button"
                href="#results"
                className="rounded-pill custom_btn mx-3 btn btn-primary"
              >
                Result
              </a>
              <a
                type="button"
                className="rounded-pill custom_btn mx-3 btn btn-outline-secondary"
                href="#courseSections"
              >
                Course fee
              </a>
              <a
                type="button"
                className="rounded-pill custom_btn mx-3 btn btn-outline-secondary"
                href="#testimonials"
              >
                Testimonials
              </a>
              <a
                type="button"
                className="rounded-pill custom_btn mx-3 btn btn-outline-secondary"
                href="#faq"
              >
                FAQ’s
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="container my-4" id="results">
    <h4 className="our_res">Our Results</h4>
    <div className="row d-flex">
      <div className="col-lg-7 col-12">
        {studentImage != "" &&
          <img src={studentImage} alt="" className="img-fluid my-4"/>
        }
        <img src="/assets/images/online-training.jpg" alt="" className="img-fluid my-4"/>
      </div>
      <div className="col-lg-5 col-12 mt-4">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            Course Overview
            <img src="/assets/images/corporate-business-women-character-pose-3d-render 1.png" alt=""/>
          </div>
          <div className="card-body">
              {(selectedSubs == '' || selectedSubs == null) ?
              <h3 style={{textAlign: "center"}}>Please select a plan first</h3>
              :
              <ul>
                <li>Subjects - {
                  subsData.length > 0 &&
                  subsData.filter((item) => item.name == selectedSubs)[0].subjects.map((s, index) => {
                    return (
                      <span key={index}>{s.name}</span>
                    )
                  })
                }</li>
                {/* {subsData.filter((item) => item.name == selectedSubs)[0].subjects.map((s, index) => {
                  return (
                    <li>{s.name}</li>
                  )
                })} */}
                {subsData.filter((item) => item.name == selectedSubs)[0].add_ons.map((a, index) => {
                  return (
                    <li key={index}>{a.name}</li>
                  )
                })}
              </ul>
              }
          </div>
        </div>
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            Course Plans
            <img src="/assets/images/3d-rendering-graphic-design 1.png" alt=""/>
          </div>
          <div className="card-body">
            {subscription.type == 'request' ? 
              <h3 style={{textAlign: "center", padding: "3rem 0"}}>Loading...</h3>
              :
              subsData.map((s_data, index) => {
                return(
                  <div className="card" key={index}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="form-check fw-bolder fs-5">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={currentSelectedPlan == s_data.id} onChange={(e) => {
                            if(e.target.checked){
                              setSelectedSubs(s_data.name)
                              setCurrentSelectedPlan(s_data.id)
                            }
                          }} />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                          </label>
                          {s_data.name}
                        </div>
                        <div className="d-grid text-end">
                          <h4>₹ {s_data.price}/-</h4>
                          {currentSelectedPlan == s_data.id &&
                          <div className="d-flex">
                            {/* <button className="border-0 btn btn-color text-white mx-1" style={{fontSize: "0.8rem !important"}}>Book a free demo</button> */}
                            <button className="border-0 btn btn-color text-white mx-1" style={{fontSize: "0.8rem !important"}} onClick={() => {
                              props.addCourse( s_data)
                              navigate('/cart', {state:  {data: {pageData: s_data, addOns:availableAddOns}}})
                            }}>Buy Now</button>
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  </section>


      <section className="container my-4 year-plan" id="courseSections">
    <h4 className="our_res">1 year course plans</h4>

    <div style={{marginTop: "2rem"}}>
      <div className="card border-0 table-card">
        <div className="card-body p-0">
          <div className="table-responsive">
            {featuresData.type == 'request' ? 
              <div style={{py:5}}>
                <h3 style={{textAlign: "center", padding: "5rem 0"}}>Loading...</h3>
              </div>
              :
              <table style={{tableLayout: "fixed",  minWidth: "800px", overflow: "auto"}} className="table my-0 text-center">
                <thead>
                  <tr>
                    <th scope="col" rowspan="2" className="align-middle plan" style={{textAlign: "start"}}>
                      Class {boards?.data?.filter((item) => item.id ==boards.selectedBoardId)[0]?.standards?.filter((s) => s.id == boards.selectedStandardId)[0]?.name_slug} <br/><span>Features</span>
                    </th>
                    {subsData.map((data,index) => {
                      return(
                          <th scope="col" className="plan" key={index}>Plan {index + 1}</th>
                      )
                    })}
                  </tr>
                  <tr>
                    {subsData.map((data,index) => {
                      return(
                        <th key={index} className="table-border-left">
                          <div className="d-flex align-items-center row row-cols-md-2 row-cols-1">
                            <div className="col-12 col-md-4">
                              {index < 3 ?
                                <img src={`/assets/images/badge${index}.svg`} alt="" className="img-fluid"/>
                                :
                                <img src="/assets/images/blue-badge.svg" alt="" className="img-fluid"/>
                              }
                            </div>
                            <div className="col-12 col-md-8 text-start plans">
                              {data.name}
                            </div>
                          </div>
                          <button type="button" className="btn btn-outline-primary rounded-pill btn-sm my-2">₹ {data.price}/-</button>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {/* {featuresData.data.map((a_on, ind) => {
                    return(
                      <tr key={ind}>
                        <th scope="row" className="list-light-hightlight " style={{textAlign: "left"}}>
                        <img src={a_on.icon} width="40" style={{objectFit: "cover"}} alt=""/> &nbsp; {Object.keys(a_on)[0]}
                        </th>
                        {subsData.map((data, index) => {
                          return(
                            <td className="blue-highlight table-border-left">
                              {ind < 8 ? 
                              <img src="/assets/images/Vector.svg" alt="available"/>
                              :
                              (a_on.name == "Personal Mentorship" || a_on.name == "8 Inch Tablet SQ84TG") ?
                               <img src="/assets/images/Vector.svg" alt="available"/>
                               :
                              <img src="/assets/images/cross.svg" alt="not available"/>
                              }
                            </td>
                          )

                        })}
                      </tr>
                    )
                  })} */}

                  {featuresData.data.map((a_on, ind) => {
                    return(
                      <tr key={ind}>
                        <th scope="row" className="list-light-hightlight " style={{textAlign: "left"}}>
                        <img src={a_on.icons} width="40" style={{objectFit: "cover"}} alt=""/> &nbsp; {Object.keys(a_on)[0]}
                        </th>
                        {/* {JSON.stringify(a_on.icons)} */}
                        {/* {JSON.stringify(Object.keys(a_on)[0])} */}
                        {a_on[Object.keys(a_on)[0]].map((is_true, index) => {
                          return(
                            <td className="blue-highlight table-border-left">
                              {is_true ?
                                <img src="/assets/images/Vector.svg" alt="available"/>
                                :
                                <img src="/assets/images/cross.svg" alt="not available"/>
                              }
                              {/* {ind < 8 ? 
                              <img src="/assets/images/Vector.svg" alt="available"/>
                              :
                              (a_on.name == "Personal Mentorship" || a_on.name == "8 Inch Tablet SQ84TG") ?
                               <img src="/assets/images/Vector.svg" alt="available"/>
                               :
                              <img src="/assets/images/cross.svg" alt="not available"/>
                              } */}
                            </td>
                          )

                        })}
                      </tr>
                    )
                  })}
                  <tr>
                    <th scope="row">
                    </th>
                    {subsData.map((data,index) => {
                      return(
                        <td className="table-border-left " key={index}>
                          <button className="btn buy-now-btn my-2" onClick={() => {
                            props.addCourse( data)
                            navigate('/cart', {state: {data: {pageData: data, addOns:availableAddOns}}})
                          }}>Buy Now</button>
                        </td>
                      )
                    })}
                  </tr>
                </tbody>
              </table>
            }
          </div>
        </div>
      </div>
    </div>
  </section>

      <section className="container my-4" id="testimonials">
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/Mask group (5).png"
            alt=""
            className="img-fluid pe-3"
          />
          <h4 className="our_res">Stories that inspire</h4>
        </div>
        <div className="card my-4 d-flex rounded-5">
          <div className="card-body">
            <div className="d-flex align-items-center row">
              {testimonialData.type == "request" ?
                <h3 className='text-center text-white my-5'>Loading...</h3>
                :
                testimonialData.type == "success" &&
                testimonialData.data.filter((i, ind) => ind <= 3).map((t_data, index) => {
                    return(
                        <div className="col-12 col-md-3 custom-section">
                        <div
                          className="card text-white bg-primary rounded-5"
                          style={{ maxWidth: "18rem", height: (readData.show && (readData.id == t_data.id)) ? "auto" : "15rem", overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                          <div className="card-body" style={{position: "relative"}}>
                            <p className="card-text">
                              {(readData.show && (readData.id == t_data.id)) ?
                                t_data.feedback
                                :
                                t_data.feedback.length > 270 ? t_data.feedback.slice(0,270) + "..." : t_data.feedback
                              }
                            </p>

                            {t_data.feedback.length > 270  &&
                              <p className="mt-3" onClick={() => {
                                setReadData({show: !readData.show, id: t_data.id})
                              }} style={{cursor: "pointer", textAlign: "center", position: "absolute", left: "50%", transform: "translateX(-50%)", bottom: "0"}}>Read More</p>
                            }

                          </div>
                        </div>
                        <div className="d-flex align-items-center my-3">
                          <img
                            src={t_data.image}
                            alt={t_data.name}
                            width="60"
                            className="img-fluid rounded-circle"
                          />
                          <p className="m-0 mx-2">{t_data.name}</p>
                        </div>
                        </div>
                    )
                })
            }
            </div>
          </div>
        </div>
      </section>

      <section className="container" id='faq'>
        <div className="d-flex align-items-center">
          <img
            src="/assets/images/Mask group.png"
            alt="Mask group"
            className="img-fluid"
            width="160"
            height="160"
          />
          <h3>Frequently asked questions</h3>
        </div>


{/*  */}
<div className="accordion" id="accordionExample">
{faqs.type == 'success' &&
    faqs.data.length > 0 ?
      faqs.data.map((item,index) => {
          return(
            <div className="accordion-item shadow" style={{margin: "1rem 0"}}>
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                  {item.question}
                </button>
              </h2>
              <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  {item.answer}
                </div>
              </div>
            </div>
          )
      })
      :
      <h3 style={{textAlign: "center", padding: "3rem 0"}}>No FAQ's</h3>
  }
</div>
{/*  */}


      </section>
    </>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
  subscription: state.subscription,
  boards: state.boards
})

const mapDispatchToProps = {
  getSubsData,
  addCourse
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription)