import axios from 'axios';
import React, { useEffect, useState } from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import useRazorpay from "react-razorpay";
import CouponCodeModal from '../../components/modals/couponCode';
import AddOnsModal from '../../components/modals/addOns';
import { getPlanWiseAddOns, verifyYourPayement } from '../../utils';
import { connect } from 'react-redux';

import { addDataToCart } from '../../services/actions';

function Cart(props) {
    const {user,cart} = props
    const navigate = useNavigate()
    const { state } = useLocation();
    const [pageData, setPageData] = useState(state.data.pageData)

    const [addOns, setAddOns] = useState({type: "", data: [], message: ""})

    const [discountPercent, setDiscountPercent] = useState('')

    const [openCoupon, setOpenCoupon] = useState(false)
    const [openAddOn, setOpenAddOn] = useState(false)

    const [courseBought, setCourseBought] = useState(false)

    const [formData, setFormData] = useState({
        name: user.data.name,
        phone: user.data.mobile_no,
        email: ""
    })

    const Razorpay = useRazorpay();

    const [apiCalled, setApiCalled] = useState({status: false, type: "checkout"})

    const [availableCoupons, setAvailableCoupons] = useState([])
    
    const [currentAddOns, setCurrentAddOns] = useState([])

    const [cCode, setCCode] = useState('')

    const [cPrice,setCPrice] = useState(pageData.price)

    const getCouponsData = async () => {
        let getCoupons = await axios.get('/api/v1/s/coupons/')

        if(getCoupons.data.status == 'error'){
          // toast.error(getCoupons.data.response.statusDescription)
        }else{
          setAvailableCoupons(getCoupons.data.response.data.coupon)
        }
    }

    const addAddon = (type,data) => {
      if(type == 'add'){
        setCurrentAddOns(prev => {
          return [...prev, data]
        })
        setCPrice(cPrice + data.price)
      }else{
        // setCurrentAddOns()
        setCPrice(cPrice - data.price)
        setCurrentAddOns(currentAddOns.filter((item) => item != data))
      }
    }

    const verifyCoupon = async () => {
        setApiCalled({status: true, type: "verify"})
        let data = {
            coupon_code: cCode,
            plan_id: pageData.id
        }
        let verifyC = await axios.post('/api/v1/s/verify-coupon/', {...data})
        setApiCalled({status: false, type: ""})

        if(verifyC.data.status == "error"){
          // toast.error(verifyC.data.response.statusDescription)
        }else{
          setDiscountPercent(verifyC.data.response.data.discount_percent)
          // toast.success(verifyC.data.response.statusDescription)
        }
    }

    const loadRazorPay = (src) => {
      return new Promise(resolve => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
          resolve(true)
        }
        script.onerror = () => {
          resolve(false)
        }
        document.body.appendChild(script)
      })
    }

    const verifyPayement = (data) => {
      verifyYourPayement(data, (res) => {
        if(res.type == "error"){
          // toast.error(res.message)
        }else{
          // toast.success(res.message)
          setCourseBought(true)
        }
      })
    }

    const showRazorPay = async (data) => {

      const {preload_data,amount,razorpay_order_id,order_id,ref_id } = data

      const res = await loadRazorPay('https://checkout.razorpay.com/v1/checkout.js')
      
      if(!res){
        alert("Something went wrong")
        return
      }

      var options = {
          "key": process.env.REACT_APP_RAZORPAY_PUB_KEY, // Enter the Key ID generated from the Dashboard
          "amount": amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          "currency": "INR",
          "name": "Eduaid", //your business name
          "description": "Buy course plan",
          "image": null,
          "order_id": razorpay_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          "callback_url": "http://localhost:3000/buyPlan",
          handler: function(resolve) {
            verifyPayement(resolve)
          },
          "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
              "name": formData.name, //your customer's name
              "email": formData.email,
              "contact": formData.phone //Provide the customer's phone number for better conversion rates 
          },
          "notes": {
              "address": "Jodhpur, Rajasthan"
          },
          "theme": {
              "color": "#3399cc"
          }
      };
      var rzp1 = new Razorpay(options);
      rzp1.open();
    
    }

    const initiateCheckout = async () => {
        let modules = []
        for (let i = 0; i < cart.items.length; i++) {
            modules.push(cart.items[i].id)
        }
        let data = {
            plans: [pageData.id],
            coupon_code: [cCode],
            modules
        }

      setApiCalled({status: true, type: "checkout"})

      let chekOut = await axios.post('/api/v1/s/checkout-v2-webapp/', {...data}, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      


      setApiCalled({status: false, type: ""})
      if(chekOut.data.status == 'error'){
      }else{
        showRazorPay(chekOut.data.response.data)
      }
      return

      if(chekOut.data.status == 'error'){
      }else{
      }


    }

    const formDataChangeHandler = (type, val) => {
        let existing_data = {...formData}
        existing_data[type] = val

        setFormData(existing_data)
    }

    const getPlanAddonData = () => {
      setAddOns({...addOns, type: "request"})
      getPlanWiseAddOns(cart.course.id, ((res) => {
        if(res.type == "success"){
          setAddOns({...addOns, type: "success", data: res.data})
        }else{
          setAddOns({...addOns, type: "error", message: res.message})
        }
      }))
    }

    useEffect(() => {
        getCouponsData()
        getPlanAddonData()
    }, [])

  return (
    <>
    <section className="cart-section">
    <ToastContainer />
        <section>
        <div className="bigimage-become">
          <div className="bgoverlay-become bg-bread">
            <nav
              className="nav-position"
              style={{"--bs-breadcrumb-divider": ">"}}
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link href="/">Home</Link>
                </li>
                <li className="breadcrumb-item text-white active" aria-current="page">
                  Cart
                </li>
              </ol>
            </nav>
            <h3>Cart</h3>
          </div>
        </div>
    </section>

    <div className="container mt-5">
        <div className="row">
            <div className="col-lg-8">
                <div className="info-card">
                    <h3 className='text-white'>Personal Information</h3>
                    <hr style={{color: "white"}}/>

                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" id="name" name='name' value={formData.name} onChange={(e) => formDataChangeHandler(e.target.name, e.target.value)} aria-describedby="emailHelp" placeholder="Your name"/>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" value={formData.email} onChange={(e) => formDataChangeHandler(e.target.name, e.target.value)} id="email" name='email' placeholder="Email"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div class="form-group">
                                    <input type="number" class="form-control" id="phone" value={formData.phone} onChange={(e) => formDataChangeHandler(e.target.name, e.target.value)} name='phone' placeholder="Phone"/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="info-card mt-5">
                    <h3 className='text-white'>Add ons</h3>
                    <hr style={{color: "white"}}/>  

                    <div className='d-flex flex-wrap justify-content-center'>
                      { addOns.type == "request" ?
                        <h3 className='text-white text-center'>Loading...</h3>
                        :
                        addOns.type == "error" ?
                        <h3 className='text-white text-center'>{addOns.message}</h3>
                        :
                        addOns.type == "success" &&
                          addOns.data.map((a_on_data, index) => {
                            return(
                              <div className='add-ons-card mx-2' key={index}>
                                <div className="a_card" onClick={() => {
                                  if(cart.items.includes(a_on_data)){
                                    addAddon('remove',a_on_data)
                                    props.addDataToCart('remove',a_on_data)
                                  }else{
                                    addAddon('add',a_on_data)
                                    props.addDataToCart('add',a_on_data)
                                  }
                                }}>
                                  {cart.items.includes(a_on_data) &&
                                    <div className='green_check'>
                                      <i class="fa-solid fa-check"></i>
                                    </div>
                                  }
                                  <img src={a_on_data.icon} alt={a_on_data.name}/>
                                  <p className='mb-0'>{a_on_data.name}</p>
                                  <p style={{fontSize: "12px"}}>₹ {a_on_data.price}</p>
                                  
                                </div>
                              </div>
                            )
                          })}
                    </div>
                    
                </div>

                <div className="info-card mt-5">
                    <h3 className='text-white'>Voucher</h3>
                    <hr style={{color: "white"}}/>

                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" id="name" name='name' aria-describedby="emailHelp" placeholder="Enter coupon"/>
                        </div>
                    </form>
                </div>
            </div>

            <div className="col-lg-4 my-2">
                <div className="info-card">
                    <h3 className='text-white'>Items</h3>
                    <hr style={{color: "white"}}/>

                    <div className='py-3 item-display d-flex align-items-center justify-content-between'>
                        <div>
                            image
                        </div>
                        <div>
                            <h6 className='text-white'>{cart.course.name}</h6>
                        </div>
                        <div className='text-white'>
                        &#8377;{cart.course.price}
                        </div>
                    </div>

                    {cart.type == "success" &&
                        cart.items.map((item,index) => {
                            return(
                                <div key={index} className='py-3 item-display d-flex align-items-center justify-content-between'>
                                    <div>
                                        <img src={item.icon} width={100} alt={item.name}/>
                                    </div>
                                    <div>
                                        <h6 className='text-white'>{item.name}</h6>
                                        <p style={{cursor: "pointer", color: "#ececec"}} onClick={() => props.addDataToCart("remove",item)}><i class="fa-solid fa-xmark"></i> Remove</p>
                                    </div>
                                    <div className='text-white'>
                                    &#8377;{item.price}
                                    </div>
                                </div>
                            )
                        })
                    }


                    <hr style={{color: "white"}}/>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <p className='text-white mb-0'>Subtotal</p>
                            <p className='text-white mb-0'>&#8377; {cart.course.price + cart.addOnTotal}</p>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <p className='text-white mb-0'>Discount</p>
                            <p className='text-white mb-0'>XX</p>
                        </div>
                    </div>
                    <hr style={{color: "white"}}/>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <h3 className='text-white'>Total</h3>
                            <h3 className='text-white'>&#8377; {cart.course.price + cart.addOnTotal}</h3>
                        </div>
                    </div>
                    <button className='checkout-btn btn-warning' onClick={initiateCheckout} style={{opacity: apiCalled.status && apiCalled.type == "checkout" ? 0.5 : 1}} disabled={apiCalled.status && apiCalled.type == "checkout"}>{apiCalled.status && apiCalled.type == "checkout" ? "Checking out..." : "Checkout"}</button>
                </div>
            </div>
        </div>
    </div>
    {openCoupon &&
      <CouponCodeModal open={openCoupon} coupons={availableCoupons} handleClose={() => setOpenCoupon(false)}/>
    }
    {openAddOn &&
      <AddOnsModal open={openAddOn} addOns={addOns} currentAddOns={currentAddOns} addAddon={addAddon} handleClose={() => setOpenAddOn(false)}/>
    }

</section>
    </>
  )
}

const mapStateToProps = (state) => ({
    user: state.user,
    cart: state.cart,
  });
  
  const mapDispatchToProps = {
    addDataToCart
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Cart);
  