import axios from "axios"
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

export const loginEduHelper = (data, cb) => {
    return async dispatch => {
        console.log(data)
        let loginData = await axios.post("/api/v1/e/eduhelper-authentication/", {...data})

        if(loginData.data.status == "success"){
            dispatch({
                type: "EDUHELPER_LOGIN_SUCCESS",
                data: loginData.data.response.data
            })
            axios.defaults.headers = {...axios.defaults.headers, Authorization: `Bearer ${loginData.data.response.data.token}`}
        }


        cb({type: loginData.data.status})
        console.log("loginData",loginData)
    }
}

export const getAllChatLists = () => {
    return async dispatch => {
        dispatch({
            type: "ALL_CHATS_REQUEST"
        })
        let pendingChatList = await axios.get("/api/v1/e/get_pending_chat_list/")

        if(pendingChatList.data.status == "success"){
            dispatch({
                type: "PENDING_CHAT_SUCCESS",
                data: pendingChatList.data.response.data.pending_list
            })
        }

        let ongoingChatList = await axios.get("/api/v1/e/get_ongoing_chat_list/")

        if(ongoingChatList.data.status == "success"){
            dispatch({
                type: "ONGOING_CHAT_SUCCESS",
                data: ongoingChatList.data.response.data.ongoing_list
            })
        }
        let closeChatList = await axios.get("/api/v1/e/get_closed_chat_list/")

        if(closeChatList.data.status == "success"){
            dispatch({
                type: "CLOSED_CHAT_SUCCESS",
                data: closeChatList.data.response.data.closed_list
            })
        }
        
    }
}