import axios from 'axios';
import React from 'react'
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'

function EduhelperHeader(props) {
    const {eduhelper} = props
    const navigate = useNavigate()


    const logoutHandler = async () => {
        console.log("eduhelper", eduhelper.data.username)
        let logoutHandler = await axios.get(`/api/v1/e/logout/?username=${eduhelper.data.username}`)

        if(logoutHandler.data.status == "success"){
            navigate('/eduhelper')
        }
    }
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
            <Link className="navbar-brand" to="/eduhelper"><img
                src="/assets/images/eduaid-logo-main.png"
                className="img-fluid"
                alt=""
              /></Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                <li class="nav-item">
                    <button class="btn btn-danger btn-sm text-white" onClick={logoutHandler}>Logout</button>
                </li>
                </ul>
            </div>
        </div>
    </nav>

  )
}

const mapStateToProps = (state) => ({
    eduhelper: state.eduhelper
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(EduhelperHeader);