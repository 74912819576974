import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import SubsCard from '../subcomponents/subsCard';
import { getDemoLectures } from '../../../utils';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

function DashboardComp(props) {
    const [demoLectureData, setDemoLectureData] = useState([])

    const [question, setQuestion ] = useState("")

    const {subscription, boards} = props

    const getDemoLectureData = () => {
        getDemoLectures(boards.selectedStandardId, (res) => {
            console.log("This is the response", res)
            if(res.type == "error"){
                // toast.error(res.message)
            }else{
                setDemoLectureData(res.data)
            }
        })
    }

    const submitQuestion = async () => {
        let data = {
            question,
            standard_id: boards.selectedStandardId
        }

        let postFAQ = await axios.post('/api/v1/s/get-faq/', {...data})


    }

    useEffect(() => {
        getDemoLectureData()
    }, [])

  return (
    <div className="page-wrapper">
        <ToastContainer />
        <div className="page-content">
            <div className="container-fluid new-div">
            <div className="row">
                <div className="col-md-8 col-sm-8">
                <div className="all-h1-p">
                    <h1>You Can Now Try Our Courses</h1>
                    <p className="text-white f-famaly">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                    </p>
                    <button>Try Now</button>
                </div>
                </div>
                <div className="col-md-4 col-sm-4">
                <div className="model-img">
                    <img src="/assets/images/admin/model-1.png" alt="" />
                </div>
                </div>
            </div>
            </div>
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                <h3 className="subs-ft">Subscriptions</h3>
                </div>
                {subscription.data.map((subs_data,index) => {
                    return(
                        <SubsCard data={subs_data} ind={index}/>
                    )
                })}
                {/* <div className="col-md-3 col-sm-4">
                <div
                    className="card gm-bx radius-10 border-0 border-info bg-color-one"
                >
                    <div className="card-body card-bdy">
                    <div className="align-items-center mt-2">
                        <div className="d-flex king-img mb-3">
                        <img
                            src="/assets/images/admin/Vector.png"
                            className="img-fluid"
                            alt=""
                        />
                        <p className="mb-0 text-white ps-2">GRAND X C</p>
                        </div>
                        <div className="d-flex th-justify mb-3">
                        <h4 className="my-1 text-info1">
                            10<sup className="sup-new">th</sup>
                        </h4>
                        <p className="mb-0 font-13 ps-2">CBSE</p>
                        </div>
                        <div className="rounded-circle text-white ms-auto mb-3">
                        <p>GRAND MASTER X C</p>
                        <p>Plan Subscriptions</p>
                        </div>
                        <div className="text-center get-btn mb-2">
                        <button>Get</button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-md-3 col-sm-4">
                <div
                    className="card gm-bx radius-10 border-0 border-4 border-info bg-color-two"
                >
                    <div className="card-body card-bdy">
                    <div className="align-items-center mt-2">
                        <div className="d-flex king-img mb-3">
                        <img src="/assets/images/admin/Vector-2.png" alt="" />
                        <p className="mb-0 text-white ps-2">MASTER X C</p>
                        </div>
                        <div className="d-flex th-justify mb-3">
                        <h4 className="my-1 text-info-two">
                            10<sup className="sup-new th-red">th</sup>
                        </h4>
                        <p className="mb-0 font-13 ps-2">CBSE</p>
                        </div>
                        <div className="rounded-circle text-white ms-auto mb-3">
                        <p>MASTER X C</p>
                        <p>Plan Subscriptions</p>
                        </div>
                        <div className="text-center get-btn mb-2">
                        <button>Get</button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-md-3 col-sm-4">
                <div
                    className="card gm-bx radius-10 border-0 border-4 border-info bg-color-three"
                >
                    <div className="card-body card-bdy">
                    <div className="align-items-center mt-2">
                        <div className="d-flex king-img mb-3">
                        <img src="/assets/images/admin/Vector-3.png" alt="" />
                        <p className="mb-0 text-white ps-2">SUPER ELITE X C</p>
                        </div>
                        <div className="d-flex th-justify mb-3">
                        <h4 className="my-1 text-info-three">
                            10<sup className="sup-new th-yellow">th</sup>
                        </h4>
                        <p className="mb-0 font-13 ps-2">CBSE</p>
                        </div>
                        <div className="rounded-circle text-white ms-auto mb-3">
                        <p>SUPER ELITE X C</p>
                        <p>Plan Subscriptions</p>
                        </div>
                        <div className="text-center get-btn mb-2">
                        <button>Get</button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-md-3 col-sm-4">
                <div
                    className="card gm-bx radius-10 border-0 border-4 border-info bg-color-four"
                >
                    <div className="card-body card-bdy">
                    <div className="align-items-center mt-2">
                        <div className="d-flex king-img mb-3">
                        <img src="/assets/images/admin/Vector-4.png" alt="" />
                        <p className="mb-0 text-white ps-2">ELITE X C</p>
                        </div>
                        <div className="d-flex th-justify mb-3">
                        <h4 className="my-1 text-info-four">
                            10<sup className="sup-new th-green">th</sup>
                        </h4>
                        <p className="mb-0 font-13 ps-2">CBSE</p>
                        </div>
                        <div className="rounded-circle text-white ms-auto mb-3">
                        <p>ELITE X C</p>
                        <p>Plan Subscriptions</p>
                        </div>
                        <div className="text-center get-btn mb-2">
                        <button>Get</button>
                        </div>
                    </div>
                    </div>
                </div>
                </div> */}
            </div>
            <div className="row">
                <div className="col-md-12">
                <h2 className="subs-ft">Demo Lecture</h2>
                </div>
                <div className="row">
                    {demoLectureData.map((item, index) => {
                        return(
                            <div className="col-md-6 col-sm-6 bordr-rt">
                                <div className="card custom-card">
                                    <div className="pr-video">
                                        <video className="video-box ifram" src={item.lecture_video}
                                            poster={item.lecture_image} controls>
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className="card-body c-body">
                                        <h6 className="card-title">
                                        {item.title}
                                        </h6>
                                        <p className="card-text">
                                        {item.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                <h2 className="subs-ft">Faq</h2>
                </div>
                <div className="col-md-11">
                <div className="text-a">
                    <div className="ask">Ask a question ?</div>
                    <textarea
                    name=""
                    id=""
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    cols="30"
                    rows="10"
                    className="text-area mt-2"
                    ></textarea>
                    <div className="sub-butn text-end">
                    <button onClick={submitQuestion}>Submit</button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>

        <div className="overlay toggle-icon"></div>
        <a href="javaScript:;" className="back-to-top"><i className="bx bxs-up-arrow-alt"></i></a>
        </div>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  subscription: state.subscription,
  boards: state.boards
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComp);
