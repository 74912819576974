import axios from 'axios'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

function ContactUs(props) {
    const {user} = props
    const [apiCalled, setApiCalled] = useState(false)
    const [formData, setFormData] = useState({
        name: "",
        email_id: "",
        message: "",
        phone: "",
        subject: ""
    })

    const handleDataChange = (type, val) => {
        let existing_data = {...formData}
        existing_data[type] = val

        setFormData(existing_data)
    }

    const submitContactUs = async (e) => {
        e.preventDefault()
        setApiCalled(true)

        let submitData = await axios.post('/api/v1/s/contact-us/', {...formData}, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        
        if(submitData.data.type == "error"){
            // toast.error(submitData.data.response.statusDescription)
        }else{
            // toast.success(submitData.data.response.statusDescription)

            setFormData({
                name: "",
                message: "",
                phone: "",
                email_id: "",
                subject: ""
            })
        }
        
        setApiCalled(false)
    } 


    return (
        <>
            <ToastContainer />
            <section>
                <div className="bigimage">
                    <div className="bgoverlay bg-bread">
                        {/* <nav className="nav-position" style={{"--bs-breadcrumb-divider: '>'"}} aria-label="breadcrumb"> */}
                        <nav className="nav-position" style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item text-white active" aria-current="page">
                                    Contact Us
                                </li>
                            </ol>
                        </nav>
                        <h3>Contact Us</h3>
                    </div>
                </div>
            </section>
            <section className="address-mrgn">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="drp-msg">
                                                <h2>Get in touch with us</h2>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="addr-box">
                                                <div className="email-flex">
                                                    <div className="dgl">
                                                        <a className="phn-bg" href="javascript:void(0)"><i
                                                            className="fa-solid fa-phone phn-i"></i></a>
                                                    </div>
                                                    <div className="cals">
                                                        <a href="tel:(+91)(94779 94779)">
                                                            <h6 className="mt-2">+91 94779 94779 </h6>
                                                        </a>
                                                    </div>
                                                </div>

                                                <div className="email-flex">
                                                    <div className="dgl">
                                                        <a className="env-bg" href="javascript:void(0)"><i
                                                            className="fa fa-envelope phn-i"></i></a>
                                                    </div>
                                                    <div className="cals">
                                                        <a href="mailto:info@eduaid.co.in">
                                                            <h6 className="mt-2">Info@eduaid.co.in</h6>
                                                        </a>
                                                    </div>
                                                </div>

                                                <div className="email-flex">
                                                    <div className="dgl">
                                                        <a className="map-bg" href="javascript:void(0)"><i
                                                            className="fa-solid fa-location-dot phn-i"></i></a>
                                                    </div>
                                                    <div className="cals">
                                                        <h6 className="floor">
                                                            SIQES EDUAID LLP. DIVINE PLAZA, 1, G.S. ROAD, 
                                                            SUPERMARKET, Assam, 781006
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="drp-msg">
                                        <h2>We will Answer all your Questions</h2>
                                        <form onSubmit={submitContactUs}>
                                            <div className="addr-box">
                                                <input required type="text" name='name' value={formData.name} onChange={(e) => handleDataChange(e.target.name, e.target.value)} placeholder="Enter Name" id="" className="form-control" />
                                                <input required type="email" name='email_id' value={formData.email_id} onChange={(e) => handleDataChange(e.target.name, e.target.value)} placeholder="Email" id="" className="form-control" />
                                                <input required type="text" name='phone' value={formData.phone} onChange={(e) => handleDataChange(e.target.name, e.target.value)} placeholder="Phone" id="" className="form-control" />
                                                <input required type="text" name='subject' value={formData.subject} onChange={(e) => handleDataChange(e.target.name, e.target.value)} placeholder="Subject" id="" className="form-control" />
                                                <textarea className="form-control" name='message' value={formData.message} onChange={(e) => handleDataChange(e.target.name, e.target.value)} placeholder="Message"
                                                    id="exampleFormControlTextarea1" rows="3"></textarea>

                                                <div className="text-end mt-4">
                                                    <button type="submit" disabled={apiCalled} className="sendmsg-btn" fdprocessedid="f9qwn">
                                                        {apiCalled ? "Submitting..." : "Submit"}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container-fluid">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57298.75729661498!2d91.7259414!3d26.1585165!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a59bff2759cb1%3A0x26f4fed60d41da21!2sEduAid!5e0!3m2!1sen!2sin!4v1691173850890!5m2!1sen!2sin" width="600" height="450" style={{border:"0"}} className="ht-iframe"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <section id="Coverage-contactus">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 bg-img-footover">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <div className="spcl">
                                        <h3>Want Us to Email you About Special Offers & Updates?</h3>
                                    </div>
                                </div>
                                {!user.login &&
                                    <div className="col-md-6 col-6 register-box">
                                        <div className="registr">
                                            <button className="regtr-btn" onClick={() => {
                                                document.getElementById(`login-btn`).click()
                                            }}>Register Now
                                                <img src="assets/images/Arrow 2.png" className="img-fluid" alt="" /></button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
  });
  
  const mapDispatchToProps = {
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
  